import classNames from "classnames"
import Label from "@/components/common/form/label"
import { fieldTypes, hideWithConditionalLogic } from "./Form"
import styles from "./Form.module.css"

export default function Page({
  form,
  fields,
  watch,
  gformUniqueId,
  currentPage,
  uploadFiles,
  setUploadFiles,
  isArticle,
  toggleModal
}) {
  return (
    <div className={styles.pageWrapper}>
      {fields.map((field, fieldIndex) => {
        if (field.type === "page") {
          return null
        }

        if (field.visibility === "administrative") {
          return null
        }

        if (field.visibility === "hidden") {
          field.type = "hidden"
        }

        if (
          field?.conditionalLogic?.enabled &&
          hideWithConditionalLogic(field, watch)
        ) {
          return null
        }

        const fieldClasses = [
          `field-${field.type}`,
          field.type != "quiz" ? "mb-2 md:mb-6" : "mb-8",
          { "has-divider": form?.quiz?.multiplePerPage && !isArticle },
          {
            "has-extra-content":
              form?.quiz?.hasImages && form?.quiz?.multiplePerPage && !isArticle
          }
        ]

        const FieldComponent = fieldTypes[field.type] ?? NotFound

        return (
          <div key={fieldIndex} className={classNames(fieldClasses)}>
            {field.labelPlacement !== "hidden" &&
              ![
                "radio",
                "hidden",
                "consent",
                "quiz",
                "tf_image",
                "tf_vote",
                "tf_quiz_result"
              ].includes(field.type) && (
                <Label name={field.key} required={field.isRequired}>
                  {field.label}
                </Label>
              )}
            <FieldComponent
              form={form}
              field={field}
              currentPage={currentPage}
              gformUniqueId={gformUniqueId}
              uploadFiles={uploadFiles}
              setUploadFiles={setUploadFiles}
              isArticle={isArticle}
              toggleModal={toggleModal}
            />
          </div>
        )
      })}
    </div>
  )
}

const NotFound = ({ field }) => {
  return (
    <span className="text-warning">Field type {field.type} not found.</span>
  )
}
