function Label({ name, children, required }) {
  return (
    <label
      htmlFor={name}
      className="inline-block mb-2 font-medium cursor-pointer">
      {children}
      {required && <span className="ml-1 text-red-100">*</span>}
    </label>
  )
}

export default Label
