import { useState, useEffect } from "react"
import { shuffleArray } from "@/lib/helpers/utils"

export function useGravityForm(incomingForm) {
  const [form, setForm] = useState(null)

  useEffect(() => {
    // If form empty or no quiz shuffle fields return as is
    if (
      !incomingForm ||
      (incomingForm.fields && incomingForm?.fields.length === 0) ||
      !incomingForm?.quiz?.shuffleFields
    ) {
      return setForm(incomingForm)
    }

    const form = { ...incomingForm }
    const quizFields = shuffleArray([
      ...form.fields.filter((field) => field.type === "quiz")
    ])

    const newFields = form.fields.map((field) => {
      if (field.type !== "quiz") {
        return field
      }

      const randomField = { ...quizFields.pop() }
      randomField.questionIndex = field.questionIndex
      randomField.pageNumber = field.pageNumber

      return { ...randomField }
    })

    form.fields = [...newFields]

    setForm(form)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingForm])

  return form
}
